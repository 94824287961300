class ScheduleJobsServiceClass {
    public socket: any;
    private namespace: string;
    constructor(_socket,_namespace) {
        this.socket = _socket;
        this.namespace = _namespace;
    }

    getAssetsPageService(query: string,projection:any, top: number, skip: number, orderby, reverse: boolean, collectionname: string,
        isMongoQuery: boolean = false) {
        return new Promise<any[]>((resolve, reject) => {
            if (!this.socket || !this.socket.connected) {
                return reject("IO.Socket not connected to /" + this.namespace);
            }
            try {
                this.socket.emit("GET_ASSETS_PAGE", query,projection, top, skip, orderby, reverse, collectionname, isMongoQuery,
                    (err, result) => {
                        if (err) {
                            return reject(err);
                        }
                        resolve(result);
                    }
                );
            } catch (err) {
                reject(err);
            }
        });
    } 
    AddUpdateScheduleJob(item:any , flows:any,isEditable:boolean) {
        return new Promise<any>((resolve, reject) => {
            if (!this.socket || !this.socket.connected) {
                return reject('IO.Socket not connected to /' + this.namespace);
            };
            try {
                this.socket.emit('ADDUPDATESCHEDULEJOB', item ,flows,isEditable, function (err, result) {
                    if(err) { return reject(err); }
                    resolve(result);
                });

            } catch (err) {
                reject(err);
            }
        });
    }
    getShedulePageService(query: string,projection:any, top: number, skip: number, orderby, reverse: boolean, collectionname: string,
        isMongoQuery: boolean = false) {
        return new Promise<any[]>((resolve, reject) => {
            if (!this.socket || !this.socket.connected) {
                return reject("IO.Socket not connected to /" + this.namespace);
            }
            try {
                this.socket.emit("GET_SCHEDULE_PAGE", query,projection, top, skip, orderby, reverse, collectionname, isMongoQuery,
                    (err, result) => {
                        if (err) {
                            return reject(err);
                        }
                        resolve(result);
                    }
                );
            } catch (err) {
                reject(err);
            }
        });
    }
     AddScheduleJob(item:any , flows:any,isEditable:boolean) {
        return new Promise<any>((resolve, reject) => {
            if (!this.socket || !this.socket.connected) {
                return reject('IO.Socket not connected to /' + this.namespace);
            };
            try {
                this.socket.emit('ADDSCHEDULEJOB', item ,flows,isEditable, function (err, result) {
                    if(err) { return reject(err); }
                    resolve(result);
                });

            } catch (err) {
                reject(err);
            }
        });
    }
   DeleteScheduleJob(id:any) {
        return new Promise<any>((resolve, reject) => {
            if (!this.socket || !this.socket.connected) {
                return reject('IO.Socket not connected to /' + this.namespace);
            };
            try {
                this.socket.emit('DELETESCHEDULEJOB', id, function (err, result) {
                    if(err) { return reject(err); }
                    resolve(result);
                });

            } catch (err) {
                reject(err);
            }
        });
    }
    UpdateScheduleStatus(item:any) {
        return new Promise<any>((resolve, reject) => {
            if (!this.socket || !this.socket.connected) {
                return reject('IO.Socket not connected to /' + this.namespace);
            };
            try {
                this.socket.emit('UPDATESCHEDULESTATUS', item , function (err, result) {
                    if(err) { return reject(err); }
                    resolve(result);
                });

            } catch (err) {
                reject(err);
            }
        });
    }

    ToggleScheduleJobStatus(id:any) {
        return new Promise<any>((resolve, reject) => {
            if (!this.socket || !this.socket.connected) {
                return reject('IO.Socket not connected to /' + this.namespace);
            };
            try {
                this.socket.emit('TOGGLESCHEDULEJOBSTATUS', id, function (err, result) {
                    if(err) { return reject(err); }
                    resolve(result);
                });

            } catch (err) {
                reject(err);
            }
        });
    }


}